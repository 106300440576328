/* rails-erb-loader-dependencies validators/maximum_bid_validator */

ClientSideValidations.validators.local["maximum_bid"] = function(element, options) {
  const buyNowAvailable = element.data('buyNowAvailable');

  if (!buyNowAvailable) return;

  const buyNowPrice = element.data('buyNowPrice');

  if (element.val() > buyNowPrice) {
    return window.I18n.t("errors.messages.maximum_bid");
  }
}
