import classNames from "classnames";
import React from "react";
import { Text } from "../../shared/components/format";

export class RegistrarInstructions extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      selected: false,
    }
  }

  render() {
    const { registrars } = this.props
    const rarList = this.props.registrars.map((reg, i) => {
      const select = () => this.setState({ current: i, selected: true })

      return <li className="list-group-item" key={i}><a onClick={select}>{reg.name}</a></li>
    })
    const { name, url } = registrars[this.state.current]
    const divClass = classNames({
      "text-center m-y-8": true,
      "hidden": !this.state.selected,
    })

    return (
      <div className= "application--component--registrar_instructions">
        <div className="btn-group text-center">
          <button
            aria-expanded="false"
            aria-haspopup="true"
            className="btn btn-ghost-gray dropdown-toggle"
            data-toggle="dropdown"
            id="registrar_select"
            type="button"
          >
            {name}
          </button>

          <ul aria-labelledby="registrar_select" className="dropdown-menu dropup">
            {rarList}
          </ul>
        </div>

        <div className={divClass}>
          <p className="text-gray-base">
            <Text id="onboarding.nameservers.registrar.instructions" />
          </p>

          <p className="t4 text-gray-base text-bold">
            <Text id="brand.nameservers" map={(s) => s.trim().split("\n").join(" | ")} />
          </p>

          <p className="m-t-3 m-b-0" style={{ /* Invalid style: "word-break": "break-all"*/ }}>
            <a href={url}>{url}</a>
          </p>
        </div>
      </div>
    )
  }
}
