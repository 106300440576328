import { boundMethod } from 'autobind-decorator'
import $ from 'jquery'

export class PurchasingOption {
  element: JQuery<HTMLElement>
  _url: string
  vat: number
  /** The conversion rate from the seller currency to the local currency */
  rate: number

  constructor(element: HTMLElement) {
    this.element = $(element)
    this._url = this.element.data('url')
    this.vat = parseFloat(this.element.data('vat') || '0')
    this.rate = parseFloat(this.element.data('rate') || '1')
    this.addOptionEventListeners()
    const autoSelect = this.element.data('auto-select')
    // give the offerbox handler a moment to initialize
    if(autoSelect) setTimeout(this.activate, 100)
  }

  activate() {
    throw 'Override this in your PurchaseOption'
  }

  update() {
    this
  }

  get valid() {
    return true
  }

  get url() {
    return this._url
  }

  @boundMethod addOptionEventListeners() {
    this.element.on('show.bs.collapse', t => {
      this.activate()
      this.element.closest(':radio').prop('checked', true)
      this.element.closest('.purchasing-option').addClass('active')
    })
    this.element.on('hide.bs.collapse', t => {
      this.element.closest('.purchasing-option').removeClass('active')
    })
  }

  applyVAT(price: number): number {
    return price * (100 + this.vat) / 100
  }

  reverseVAT(price: number): number {
    return price / ((100 + this.vat) / 100)
  }

  toLocalCurrency(salePrice: number): number {
    return salePrice * this.rate
  }
}
