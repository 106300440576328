import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { bisector } from 'd3-array';
import { scaleOrdinal, schemeCategory20 } from 'd3-scale';
import { area, curveLinear, line } from 'd3-shape';

import Chart from './Chart';
import Axis from './Axis';
import Path from './Path';

import DefaultPropsMixin from './DefaultPropsMixin';
import HeightWidthMixin from './HeightWidthMixin';
import ArrayifyMixin from './ArrayifyMixin';
import AccessorMixin from './AccessorMixin';
import DefaultScalesMixin from './DefaultScalesMixin';

const { array, func, string } = PropTypes;

const DataSet = createReactClass({
    propTypes: {
        data: array.isRequired,
        area: func.isRequired,
        line: func.isRequired,
        colorScale: func.isRequired,
        stroke: func.isRequired
    },

    render() {
        const {
            data,
            area,
            colorScale,
            values,
            label,
            onMouseEnter,
            onMouseLeave
        } = this.props;

        const areas = data.map((stack, index) => <Path
            key={`${label(stack)}.${index}`}
            className="area"
            stroke="none"
            fill={colorScale(label(stack))}
            d={area(values(stack))}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data={data}
        />);

        return <g>{areas}</g>;
    }
});

const AreaChart = createReactClass({
    mixins: [
        DefaultPropsMixin,
        HeightWidthMixin,
        ArrayifyMixin,
        AccessorMixin,
        DefaultScalesMixin
    ],

    propTypes: {
        interpolate: func,
        stroke: func
    },

    getDefaultProps() {
        return {
            interpolate: curveLinear,
            stroke: scaleOrdinal(schemeCategory20)
        };
    },

    render() {
        const {
            height,
            width,
            margin,
            colorScale,
            interpolate,
            stroke,
            values,
            label,
            x,
            y,
            y0,
            xAxis,
            yAxis,
            yOrientation
        } = this.props;

        const data = this._data;
        const innerWidth = this._innerWidth;
        const innerHeight = this._innerHeight;
        const xScale = this._xScale;
        const yScale = this._yScale;

        const dLine = line()
            .x(e => xScale(x(e)))
            .y(e => yScale(y0(e) + y(e)))
            .curve(interpolate);

        const dArea = area()
            .x(e => xScale(x(e)))
            .y0(e => yScale(yScale.domain()[0] + y0(e)))
            .y1(e => yScale(y0(e) + y(e)))
            .curve(interpolate);

        return (
            <div>
                <Chart height={height} width={width} margin={margin}>
                    <DataSet
                        data={data}
                        line={dLine}
                        area={dArea}
                        colorScale={colorScale}
                        stroke={stroke}
                        label={label}
                        values={values}
                        onMouseEnter={id => id}
                        onMouseLeave={id => id}
                    />
                    <Axis
                        className="x axis"
                        orientation="bottom"
                        scale={xScale}
                        height={innerHeight}
                        width={innerWidth}
                        {...xAxis}
                    />
                    <Axis
                        className="y axis"
                        orientation={yOrientation ? yOrientation : 'left'}
                        scale={yScale}
                        height={innerHeight}
                        width={innerWidth}
                        {...yAxis}
                    />
                    {this.props.children}
                </Chart>
            </div>
        );
    }
});

export default AreaChart;
