import {DomainFetcher} from "./domain_fetcher"
import {DomainList} from "./domain_list"

class SuggestedDomains {
  _element: any

  constructor(public element, url) {
    this._element = element
    DomainFetcher.fetchDomains(url, this.renderList.bind(this))
  }
  renderList(jsonString){
    let json = JSON.parse(jsonString)
    DomainList.render(json, this._element)
  }
}

export function jqLoadSuggestedDomains() {
  $('.suggested-domains-list').each(function(index, element) {
    let url = $(element).data('url')
    if(url === null || url === undefined)return
    new SuggestedDomains(element, url)
  })
}
