import "bootstrap";
import ReactOnRails from "react-on-rails";
import { jqAccordionBehavior } from "../../src/public/components/accordion_behavior";
import { jqSetupClientSideValidations } from "../../src/public/components/client_side_validations";
import { jqDropdownBehavior } from "../../src/public/components/dropdown_behavior";
import { trustpilotBehavior } from "../../src/public/components/trustpilot_behavior";
import { jqFormInputResponsiveClassesBehavior } from "../../src/public/components/forms/toggle_value_class_on_inputs_behavior";
import { jqOfferBoxBehavior } from "../../src/public/components/offerbox";
import { jqPlaceholderDesktopBehavior } from "../../src/public/components/placeholder_behavior";
import { jqReadMoreCollapseBehavior } from "../../src/public/components/read_more_collapse_behavior";
import { jqReadMoreModalBehavior } from "../../src/public/components/read_more_modal_behavior";
import { jqLoadSuggestedDomains } from "../../src/public/components/suggested_domains/suggested_domains";
import { jqSignupBehavior } from "../../src/public/pages/signup";
import { TransactionFinder } from "../../src/public/components/transaction_finder";
import { TransactionValidator } from "../../src/public/components/transaction_validator";
import { RegistrarInstructions } from '../../src/search/components/registrar_instructions';
import { DomainTraffic } from '../../src/search/components/domain_traffic';
import ReactTelephoneInput from "react-telephone-input"
import { jqIntlTelInputBehavior } from "../../src/public/components/intl_tel_input_behavior";
import { jqUnsubscribeBehavior } from "../../src/public/pages/unsubscribe";
import { jqCookiePopupBehavior } from "../../src/public/components/cookie_popup_behavior";
import { jqSessionTwoFactorAuthenticationBehavior } from "~/src/public/pages/session_two_factor_authentication";

// Setup React components for rendering
ReactOnRails.register({
  RegistrarInstructions,
  TransactionFinder,
  TransactionValidator,
  ReactTelephoneInput
})

ReactOnRails.register({ DomainTraffic })

// Setup JQuery responsive elements
jqDropdownBehavior()
trustpilotBehavior()
jqReadMoreModalBehavior()
jqPlaceholderDesktopBehavior()
jqOfferBoxBehavior()
jqFormInputResponsiveClassesBehavior()
jqAccordionBehavior()
jqSetupClientSideValidations()

// Setup JQuery responsive elements on page load completion
$(function () {
  jqSignupBehavior()
  jqSessionTwoFactorAuthenticationBehavior()
  jqReadMoreCollapseBehavior()
  jqLoadSuggestedDomains()
  jqIntlTelInputBehavior()
  jqUnsubscribeBehavior()
  jqCookiePopupBehavior()
})
