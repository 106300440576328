import { sum } from "d3-array"
import { nest } from "d3-collection"
import { timeFormat } from "d3-time-format"

const day = (x) => x[0]
const counts = (x) => x[1]

export const sumBy = (k) => {
  return (data) => nest()
    .key((d) => k(new Date(day(d))))
    .rollup((d) => sum(d, counts) as any)
    .entries(data)
}

export const toQ = (d) => {
  const month = d.getMonth()

  return new Date(d.getFullYear(), month - (month % 3), 1)
}

export const formatQ = (d) => {
  const m = d.getMonth()
  const q = ((m - (m % 3)) / 3) + 1

  return "Q" + q + timeFormat("%y")(d)
}

export const guessInterval = (data) => {
  if (data.length === 0) {
    return "first"
  }

  const a = new Date(data[0][0])
  const b = new Date(data[data.length - 1][0])
  const days = (b.getTime() - a.getTime()) / (1000 * 60 * 60 * 24)

  if (days < 1) {
    return "first"
  }
  if (days < 14) {
    return "day"
  }
  if (days < 60) {
    return "week"
  }
  if (days < 365) {
    return "month"
  }

  return "quarter"
}

export const nopInt = (x) => (x === Math.ceil(x) ? x : "")

export const mkTick = (unit, num) => (scale) => scale.ticks(unit, num)
