// The validator variable is a JSON Object
// The selector variable is a jQuery Object
window.ClientSideValidations.validators.local["custom_confirmation"] = function(element, options) {
  var confirmation_element_id = element.prop('id')
  var element_confirmed_id = confirmation_element_id.replace('confirmation_', '')
  var element_confirmed = $("#" + element_confirmed_id)

  if (element.val() && element.val() !== element_confirmed.val()) {
    // When the value fails to pass validation you need to return the error message.
    return window.I18n.t("errors.messages.custom_confirmation")
  }
}