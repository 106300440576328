// Important:
// No need for window. prefix or import I18n from 'i18n-js'
// Client: I18n will be taken from window object
// Server: Execution context contains override for I18n functions (rails)
declare let I18n: any // lets typescript know that I18n was already declared

/*
 * Count can be used both for pluralization and interpolation.
 */
export function getI18nText({
  id,
  defaultValue,
  count,
  map,
  ...values
}: {
  id: string
  defaultValue?: string
  count?: number | string
  map?: any
  [key: string]: any
}) {
  const t = I18n.t(id, { defaultValue, count, ...values })
  return map ? map(t) : t
}

export function getI18nNumber(value: number) {
  return I18n.l('number', value)
}

export function getI18nPrice({
  value,
  unit = '€',
  precision = 0,
  delimiter = I18n.delimiter,
  format = I18n.format
}: {
  value: number | string
  unit?: string
  precision?: number
  delimiter?: string
  format?: string
}) {
  return I18n.toCurrency(value, { precision, unit, delimiter, format })
}

export function toLocaleDate(
  date: string | number | Date,
  monthLength: 'short' | 'long' = 'short'
) {
  return new Date(date).toLocaleDateString(I18n.locale, {
    day: 'numeric',
    month: monthLength,
    year: 'numeric'
  })
}

export function nameForPeriod(
  months: number,
  options?: { short?: boolean; nospace?: boolean }
) {
  options = options || {}
  const space = options.nospace ? '' : ' '
  if (months % 12 === 0) {
    const years = months / 12
    return `${years}${space}${
      years === 1
        ? periodLabel('year', options.short)
        : periodLabel('years', options.short)
    }`
  }
  return `${months}${space}${
    months === 1
      ? periodLabel('month', options.short)
      : periodLabel('months', options.short)
  }`
}

function periodLabel(name: string, isShort?: boolean) {
  return getI18nText({ id: `period.${isShort ? 'short' : 'normal'}.${name}` })
}
