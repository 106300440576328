export class DomainFetcher {
  static fetchDomains(url, callback){
    this._get(url, callback)
  }
  //simple async GET
  static _get(url, callback) {
    const request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (request.readyState == 4 && request.status == 200) {
        callback(request.responseText);
      }
    }
    request.open( "GET", url, true );
    request.send( null );
  }
}