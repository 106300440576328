export const VAT_PLACEHOLDERS = {
  "PL": "PL1234567890",
  "CY": "CY12345678X",
  "SI": "SI12345678",
  "RO": "RO1234567890",
  "MT": "MT12345678",
  "SK": "SK1234567890",
  "DK": "DK12345678",
  "PT": "PT123456789",
  "GR": "EL123456789",
  "LV": "LV12345678901",
  "HR": "HR12345678901",
  "NL": "NL123456789B01",
  "ES": "ESX1234567X",
  "CZ": "CZ12345678",
  "DE": "DE123456789",
  "SE": "SE123456789012",
  "GB": "GB123456789",
  "EE": "EE123456789",
  "IE": "IE1234567X",
  "LT": "LT123456789",
  "BG": "BG1234567890",
  "HU": "HU12345678",
  "BE": "BE1234567890",
  "FI": "FI12345678",
  "LU": "LU12345678",
  "AT": "ATU12345678",
  "IT": "IT12345678901",
  "FR": "FRXX123456789"
}

