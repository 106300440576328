// The validator variable is a JSON Object
// The selector variable is a jQuery Object
ClientSideValidations.validators.local["vat"] = function(element, options) {
  var R = /(?:^ATU[0-9]{8}(?=\n?$))|(?:^BE0[0-9]{9}(?=\n?$))|(?:^BG[0-9]{9,10}(?=\n?$))|(?:^CY(?!12)[0-59][0-9]{7}[A-Z](?=\n?$))|(?:^CZ[0-9]{8,10}(?=\n?$))|(?:^DE[0-9]{9}(?=\n?$))|(?:^DK[0-9]{8}(?=\n?$))|(?:^EE10[0-9]{7}(?=\n?$))|(?:^EL[0-9]{9}(?=\n?$))|(?:^ES([A-Z][0-9]{8}|[0-9]{8}[A-Z]|[A-Z][0-9]{7}[A-Z])(?=\n?$))|(?:^FI[0-9]{8}(?=\n?$))|(?:^FR[A-Z0-9]{2}[0-9]{9}(?=\n?$))|(?:^GB([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})(?=\n?$))|(?:^HR[0-9]{11}(?=\n?$))|(?:^HU[0-9]{8}(?=\n?$))|(?:^IE([0-9][A-Z][0-9]{5}|[0-9]{7}[A-Z]?)[A-Z](?=\n?$))|(?:^IT[0-9]{11}(?=\n?$))|(?:^LT([0-9]{7}1[0-9]|[0-9]{10}1[0-9])(?=\n?$))|(?:^LU[0-9]{8}(?=\n?$))|(?:^LV[0-9]{11}(?=\n?$))|(?:^MT[0-9]{8}(?=\n?$))|(?:^NL[0-9]{9}B[0-9]{2}(?=\n?$))|(?:^PL[0-9]{10}(?=\n?$))|(?:^PT[0-9]{9}(?=\n?$))|(?:^RO[1-9][0-9]{1,9}(?=\n?$))|(?:^SE[0-9]{10}01(?=\n?$))|(?:^SI[0-9]{8}(?=\n?$))|(?:^SK[0-9]{10}(?=\n?$))/
  var sanitized = element.val().toUpperCase().replace(/\.|\s/g, "")

  if (sanitized.length === 0) { return }

  if (!R.test(sanitized)) {
    // When the value fails to pass validation you need to return the error message.
    return window.I18n.t("errors.messages.invalid");
  }
  // TODO: HACK
  var selectedCountryCode = $("#checkout_country_code").val().toUpperCase()
  selectedCountryCode = selectedCountryCode.replace("GR", "EL")
  if (sanitized.indexOf(selectedCountryCode) === -1) {
    return window.I18n.t("activemodel.errors.models.checkout.attributes.vat_number.country_code_mismatch");
  }
}
