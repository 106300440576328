export function jqSessionTwoFactorAuthenticationBehavior() {
  $('#session_two_factor_authentication_otp_attempt').on('element:validate:pass.ClientSideValidations', function() {
    $('input[type="submit"]').prop('disabled', false)
  })

  $('#session_two_factor_authentication_otp_attempt').on('element:validate:fail.ClientSideValidations', function() {
    $('input[type="submit"]').prop('disabled', true)
  })

  $('#session_two_factor_authentication_otp_attempt').on('input', function(event) {
    let input = $(event.target)
    let form = input.closest('form')

    let validators = (form[0] as any).ClientSideValidations.settings.validators;
    (input.data('changed', true) as any).isValid(validators);
  })
}
