export function jqPlaceholderDesktopBehavior() {
  $("input[data-placeholder-desktop]").each((index, element) => {
    const screenWidth = $(window).width() || 0
    if (screenWidth <= 800) {
      $(element).attr("placeholder", $(element).data('placeholder-mobile'))
    } else if (screenWidth <= 1076) {
      $(element).attr("placeholder", $(element).data('placeholder-tablet'))
    } else {
      $(element).attr("placeholder", $(element).data('placeholder-desktop'))
    }
  })
}
