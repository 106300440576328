import $ from "jquery"

export function jqFormInputResponsiveClassesBehavior() {
  const inputElements = 'input[type=text], input[type=password], input[type=email], input[type=tel], textarea, select, input[type=number]'
  const inputEventChanges = 'keyup change'
  const toggleClassName = 'has-value'

  $(inputElements).on(inputEventChanges, function() {
    $(this).parent('.form-group').toggleClass(toggleClassName, $(this).val() != "")
  });

  $(function(){
    $(inputElements).each(function() {
      $(this).parent('.form-group').toggleClass(toggleClassName, $(this).val() != "")
    })
  });
}

export function jqFormCheckboxResponsiveClassesBehavior() {
  // update radio status on load
  $(function() {
    $('input[type=radio]').each(updateRadio)
  })

  // update radio status on clicking a radio button
  $('input[type=radio]').on('click', updateRadio)

  function updateRadio() {
    $(this).closest('.form-group').children('.radio').each(function() {
      const input = $(this).children('input')
      if(input.is(':checked') && !input.is(':disabled')) {
        $(this).addClass('checked')
      } else {
        $(this).removeClass('checked')
      }
      if(input.is(':disabled')) {
        $(this).addClass('disabled')
      } else {
        $(this).removeClass('disabled')
      }
    })
  }
}

export function jqFormBackgroundColorToggle() {
  const bgColorOption = $('.background-color-option :input')
  const bgImageOption = $('.background-image-option :input')
  if(!bgColorOption || !bgImageOption) return;

  updateBackgroundColorSection()
  $(bgColorOption).on('click', updateBackgroundColorSection)
  $(bgImageOption).on('click', updateBackgroundColorSection)

  function updateBackgroundColorSection() {
    if(bgColorOption.is(':checked')) {
      $('.domain-background-colors').show()
    } else {
      $('.domain-background-colors').hide()
    }
  }
}

export function jqFormCustomColorToggle() {
  const customInput = $('.custom-color-input :input')

  updateCustomSection()
  $('input[type=radio]').on('click', updateCustomSection)

  function updateCustomSection() {
    if(customInput.is(':checked')) {
      $('.custom-color-section').show()
    } else {
      $('.custom-color-section').hide()
    }
  }
}

export function jqFormDefaultSettingsToggle() {
  const defaultSettings = $('.default-settings :input')
  if(!defaultSettings) return;

  setTimeout(toggleDomainSalePageSettings, 1000)
  $('input[type=radio]').on('click', toggleDomainSalePageSettings)
  
  function toggleDomainSalePageSettings() {
    if(defaultSettings.is(':checked')) {
      $('.domain-sale-page-settings').addClass('disabled')
      $('fieldset.form-group').prop('disabled', true)
    } else {
      $('.domain-sale-page-settings').removeClass('disabled')
      $('fieldset.form-group').prop('disabled', false)
    }
  }
}


export function jqRetriggerResponsiveClassesBehaviorOn(selector: string, event: string) {
  $(selector).on(event, function() {
    jqFormCheckboxResponsiveClassesBehavior()
    jqFormCheckboxResponsiveClassesBehavior()
  })
}