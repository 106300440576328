import { boundMethod } from "autobind-decorator";
import axios from "axios";
import React from "react";
import { update } from "../../search/utils/utils";
import { Text } from '../../shared/components/format';
import { SearchBar } from "../../shared/components/search_bar";
import { getI18nText } from '../../shared/utils/translate';

const I18N_ROOT = "public.transaction_explorer.show.validate"

interface Props {
  url: string
}

interface State {
  query: string
  result?: Result
}

interface Result {
  transactionHash: string
  timestamp: number
  eventName: string
  domainName: string
  blockNumber: number
  error?: string
}

export class TransactionValidator extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      query: ''
    }
  }

  render() {
    const { query, result } = this.state
    const hasError = result && result.error
    return (
      <div>
        <SearchBar
          search
          placeholder={getI18nText({ id: I18N_ROOT + '.placeholder' })}
          submitLabel={getI18nText({ id: I18N_ROOT + '.search' })}
          onChange={this.onChangeSearch}
          onSubmit={this.onSubmit}
          value={query}
        />
        {hasError
          ? this.renderError()
          : this.renderResult()
        }
      </div>
    )
  }

  @boundMethod renderError() {
    return (
      <div className="card result p-3 mt-3">
        <Text id={I18N_ROOT + '.invalid'} />
      </div>
    )
  }

  @boundMethod renderResult() {
    const r = this.state.result
    if (!r) return;
    
    // Date uses milliseconds, so we need to multiply timestamp by 1000
    const date = new Date(r.timestamp * 1000)
    
    return <div className="card result p-3 mt-3">
      <div>
        <b><Text id={I18N_ROOT + '.domain_name'} />:</b>&nbsp;
        <span>{r.domainName}</span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.transaction_type'} />:</b>&nbsp;
        <span>{r.eventName}</span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.transaction_timestamp'} />:</b>&nbsp;
        <span>{date.toUTCString()}</span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.block'} />:</b>&nbsp;
        <span>{r.blockNumber}</span>
      </div>
    </div>
  }

  @boundMethod onChangeSearch(value: string) {
    update(this, state => state.query = value)
  }

  @boundMethod onSubmit() {
    this.fetchResult(this.state.query)
  }

  async fetchResult(domain: string) {
    const searchURL = this.props.url + domain
    try {
      const response = await axios.get(searchURL)
      if (response.status !== 200) throw Error(`returned status ${response.status}: ${response.statusText}`)
      update(this, state => state.result = response.data)
    } catch (error) {
      throw Error(`Dan explorer call failed: ${error}`)
    }
  }

}
