export function jqDropdownBehavior() {
  $('#mobile-nav').on('show.bs.collapse', function (f) {
    if(f.target.id === 'mobile-nav') {
      $('nav').addClass('expanded')
      $('.content').hide()
      $('.trustpilot-widget').addClass('hidden')
      $('body').addClass('grey')
    }
  })
  $('#mobile-nav').on('hidden.bs.collapse', function (f) {
    if (f.target.id === 'mobile-nav') {
      $('nav').removeClass('expanded');
      $('.content').show()
      $('.trustpilot-widget').removeClass('hidden');
        $('body').removeClass('grey')
    }
  })
}
