export function jqReadMoreCollapseBehavior() {
  $('.read-more-collapse').each(function(index, element){
    $(element).on('show.bs.collapse', function (e) {
      const toggledBy = $(e.target).data('toggled-by')
      const toggle = $(':visible').find(toggledBy)
      toggle.addClass("open")
      toggle.find(".label").text(toggle.data("expanded"))

    })
    $(element).on('hide.bs.collapse', function (e) {
      const toggledBy = $(e.target).data('toggled-by')
      const toggle = $(':visible').find(toggledBy)
      toggle.removeClass("open")
      toggle.find(".label").text(toggle.data("collapsed"))
    })
  })
}
