import { getI18nPrice, getI18nText } from "../../../shared/utils/translate";

export class DomainList {
  static render(json, element) {
    let domainString = ""
    json.domains.forEach((domain) => {
      domainString += this._html_for_domain(domain);

    });
    element.innerHTML = domainString
  }

  static _html_for_domain(domain) {
    return " \
    <div class='card domain-card'> \
      <a href='"+ domain.marketplace_url + "' class='domain-card-link' target='_blank'>" + domain.display_name + "</a> \
      "+ this._html_for_prices(domain) + " \
      <a href='"+ domain.marketplace_url + "' class='btn btn-sm btn-ghost domain-card-button' target='_blank'><span>" + getI18nText({ id: "public.label." + domain.button }) + "</span></a> \
    </div>"
  }

  static _html_for_prices(domain) {
    let price_element = ""

    if (domain.buy_now_price !== null && !['make_offer', 'request_price', 'rent'].includes(domain.button)) {
      price_element += " \
        <div class='prices-block'> \
          "+ this._html_for_price(domain.buy_now_price, domain.currency_sign, getI18nText({ id: "public.label.now" }), " ") + " \
          "+ this._html_for_price(domain.monthly_payment_price, domain.currency_sign, getI18nText({ id: "public.label.month" }), "<p class='divider'>or</p>") + " \
        </div>"
    }

    if (domain.button == 'rent') {
      price_element += " \
        <div class='prices-block'> \
          "+ this._html_for_price(domain.rent_fee, domain.currency_sign, getI18nText({ id: "public.label.month" }), " ") + " \
        </div>"
    }
    return price_element
  }

  static _html_for_price(price, currency, label, divider) {
    return price !== null ? divider + "<div class='domain-card-price align-items-center'> <div class='pricing text-bold text-center'>" + getI18nPrice({ value: price, unit: currency }) + "<span>/" + label + "</span></div></div>" : "";
  }
}

