/* rails-erb-loader-dependencies validators/email_validator */

ClientSideValidations.validators.local["email"] = function(element, options) {
  if(!element.val()) { return window.I18n.t("errors.messages.blank") }

  var R = /^[+\x2D.0-9A-Z_a-z]+@[\x2D0-9a-z]+(\.[a-z]+)*\.[a-z]+$/i
  if (!R.test(element.val())) {
    // When the value fails to pass validation you need to return the error message.
    return window.I18n.t("errors.messages.invalid");
  } else {
      var confirmation_email_el = $('#' + element.prop('id').replace('email', 'confirmation_email'))
      if (confirmation_email_el.length && confirmation_email_el.val()) {
          $(confirmation_email_el).data('changed', true)
          $(confirmation_email_el).isValid($('form')[0].ClientSideValidations.settings.validators)
      }
  }
}

