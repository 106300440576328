import { boundMethod } from "autobind-decorator";
import axios from "axios";
import React from "react";
import { update } from "../../search/utils/utils";
import { Text } from '../../shared/components/format';
import { SearchBar } from "../../shared/components/search_bar";
import { getI18nText } from '../../shared/utils/translate';

const I18N_ROOT = "public.transaction_explorer.show.who_owns"

interface Props {
  url: string
}

interface State {
  query: string
  result?: Result
}

interface Result {
  domainName: string
  isRental: boolean
  domainShares: number[]
  installments: boolean
  lastTransferTimestamp: number
  error?: string
}

export class TransactionFinder extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      query: ''
    }
  }

  render() {
    const { query, result } = this.state
    const hasError = result && result.error
    return (
      <div>
        <SearchBar
          search
          placeholder={getI18nText({ id: I18N_ROOT + '.placeholder' })}
          submitLabel={getI18nText({ id: I18N_ROOT + '.search' })}
          onChange={this.onChangeSearch}
          onSubmit={this.onSubmit}
          value={query}
        />
        {hasError
          ? this.renderError()
          : this.renderResult()
        }
      </div>
    )
  }

  @boundMethod renderError() {
    return (
      <div className="card result p-3 mt-3">
        <Text id={I18N_ROOT + '.not_found'} />
      </div>
    )
  }

  @boundMethod renderResult() {
    const r = this.state.result
    if (!r) return;

    return <div className="card result p-3 mt-3">
      <div>
        <b><Text id={I18N_ROOT + '.domain_name'} />:</b>&nbsp;
        <span>{r.domainName}</span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.domain_rented'} />:</b>&nbsp;
        <span><Text id={I18N_ROOT + (r.isRental ? '.confirm' : '.deny')} /></span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.domain_leased'} />:</b>&nbsp;
        <span><Text id={I18N_ROOT + (r.installments ? '.confirm' : '.deny')} /></span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.amount_shares'} />:</b>&nbsp;
        <span>{r.domainShares[0]}</span>
      </div>
      <div>
        <b><Text id={I18N_ROOT + '.amount_owners'} />:</b>&nbsp;
        <span>1</span>
      </div>
    </div>
  }

  @boundMethod onChangeSearch(value: string) {
    update(this, state => state.query = value)
  }

  @boundMethod onSubmit() {
    this.fetchResult(this.state.query)
  }

  async fetchResult(transactionID: string) {
    const searchURL = this.props.url + transactionID
    try {
      const response = await axios.get(searchURL)
      if (response.status !== 200) throw Error(`returned status ${response.status}: ${response.statusText}`)
      update(this, state => state.result = response.data)
    } catch (error) {
      throw Error(`Dan explorer call failed: ${error}`)
    }
  }

}
