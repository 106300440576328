import React from 'react';
import { getI18nNumber, getI18nPrice, getI18nText } from '../utils/translate';

export function Text(args: any) {
	return <span>{args['to-lowercase'] ? getI18nText(args).toLowerCase() : getI18nText(args)}</span>
}

export function TextHTML(args: any) {
	return <span dangerouslySetInnerHTML={{ __html: getI18nText(args) }} />
}

export function Number({ value }) {
	return <span>{getI18nNumber(value)}</span>
}

export function Price(args: { value: number | string, unit: string }) {
	return <span className="price" dangerouslySetInnerHTML={{ __html: getI18nPrice(args) }} />
}

export function LeaseToOwnPrice({ value: leasePrice, unit}: { value: number, buyNowPrice?: number, unit: string }) {
	return <div className="domain-lease-price">
		<Price value={leasePrice} unit={unit}/>
		<span className="per-month-label">{priceLabel('per_month')}</span>
	</div>
}

export function BuyNowPrice({ value, unit}: { value: number, unit: string }) {
	return <div className="domain-buynow-price">
		<Price value={value} unit={unit}/>
		<span className="buynow-label">{priceLabel('buynow')}</span>
	</div>
}

export function LeaseToOwnSearchPrice({ leasePrice, buyNowPrice, unit }: { leasePrice: number, buyNowPrice?: number, unit: string }) {
	return <div className="domain-lease-price">
		<div>{priceLabel('from')} <span className="lease-price">{unit}{leasePrice}</span> <span className="per-month-label">{priceLabel('per_month')}</span></div>
		{ buyNowPrice && 
			<div>{priceLabel('or_buy_now_for')} <span className="buynow-price">{unit}{buyNowPrice}</span></div>
		}
	</div>

}

function priceLabel(id: string) {
	return getI18nText({ id: `search.results.price.${id}`})
}
