import { boundMethod } from "autobind-decorator"
import $ from "jquery"
import { setDisabled } from "../../../shared/utils/jquery"
import { getI18nPrice } from "../../../shared/utils/translate"
import { PurchasingOption } from "./purchasing_option"


interface Range {
  value: number
  min: number
  max: number
}

const SLIDER = '.range-slider__range'
const YEAR_STOP_WIDTH_PX = 12;

export class PurchasingOptionLeaseToOwn extends PurchasingOption {
  price: number
  markups: any

  constructor(element: HTMLElement) {
    super(element)

    this.price = this.element.data('price')
    this.markups = this.element.data('markups')

    this.setEventListeners()
    // Ben: Weird to do because the value of the element is correct but Firefox seems to cache its previous state across pages
    $(SLIDER).val(this.range.max)

    // CV: respond to modal dialog being openened, we need to reattach
    // all event listeners then, because the dialog copies in the html
    const that = this
    $('a[data-content="leaseToOwnLearnMore"]').on('click', e => {
      const value = that.range.value
      setTimeout(() => {
        $(SLIDER).val(value)
        that.setEventListeners()
        that.setMonthStops()
      }, 100)
    })
  }

  @boundMethod activate() {
    this.update()
    this.element.trigger('purchase-option', this)
    setTimeout(this.setMonthStops, 10)
  }

  @boundMethod update() {
    this.updateLeaseValues()
    this.updateRangeButtons()
    this.updateRangeTooltipPosition()
  }

  @boundMethod setMonthStops() {
    const YEAR_TO_MONTHS = 12
    const START_MONTH = 2

    const stopsEl = $('.year-stops', this.element)
    const width = stopsEl.width()

    if(!width) return;

    stopsEl.children().remove()

    const stops = this.range.max - START_MONTH + 1
    const offsetPerStop = (width + (YEAR_STOP_WIDTH_PX / 2)) / stops

    for(let i = START_MONTH; i <= this.range.max; i++) {
      const atStart = (i === START_MONTH)
      const atYear = (i % YEAR_TO_MONTHS === 0)

      if(!atStart && !atYear) continue;

      const offset = (i-START_MONTH) * offsetPerStop - (YEAR_STOP_WIDTH_PX / 2)
      stopsEl.append(`<span class="year-stop" style="left: ${offset}px"></span>`)
    }
  }


  get url() {
    return this._url + '&number_of_installments=' + this.range.value
  }

  get range(): Range {
    const rangeSlider = $(SLIDER)
    const value = parseInt(rangeSlider.val() + '' || '0', 10)
    const min = parseInt(rangeSlider.attr('min') || '0')
    const max = parseInt(rangeSlider.attr('max') || '100')
    return { value, min, max }
  }

  get markup(): number {
    const months = this.range.value
    for (const [key, value] of Object.entries(this.markups).sort((a, b) => a[0].localeCompare(b[0]))) {
      if (months <= parseInt(key, 10)) return value as number
    }
    return 0
  }

  // sets event handlers, can be called repeatedly to attach again
  @boundMethod setEventListeners() {
    $(".btnDecrement").off('click')
    $(".btnDecrement").on('click', () => this.onRangeUpdate(this.range.value - 1)
    )

    $(".btnIncrement").off('click')
    $(".btnIncrement").on('click', () => this.onRangeUpdate(this.range.value + 1))

    $(SLIDER).off('input')
    $(SLIDER).on('input', e => {
      const rangeElement = e.currentTarget as HTMLInputElement
      const value = parseInt(rangeElement.value)
      this.onRangeUpdate(value)
      this.update()
    })

    $(window).on('resize', this.setMonthStops)
  }

  @boundMethod onRangeUpdate(value: number) {
    $(SLIDER).val(value)
    this.update()
    this.element.trigger('purchase-option', this)
  }

  @boundMethod updateRangeButtons() {
    const range = this.range
    const atStart = range.value == range.min
    const atEnd = range.value == range.max

    setDisabled($(".btnDecrement"), atStart)
    setDisabled($(".btnIncrement"), atEnd)
  }

  @boundMethod updateRangeTooltipPosition() {
    $(".range-slider__bubble").each((i, el) => {
      const leftOffset = Number(((this.range.value - this.range.min) * 100) / (this.range.max - this.range.min))
      // Ben: Sorta magic numbers based on size of the native UI thumb
      $(el).css('left', `calc(${leftOffset}% + (${12 - leftOffset * 0.25}px))`)
    })
  }

  @boundMethod updateLeaseValues() {
    const months = this.range.value
    const markup = this.markup
    const totalPriceInc = this.price
    const totalPriceEx = this.reverseVAT(totalPriceInc)
    const monthlyEx = totalPriceEx / months
    const monthlyMarkupEx = monthlyEx * (markup / 100.0)
    const monthlyPaymentEx = monthlyEx + monthlyMarkupEx
    const monthlyPaymentInc = this.applyVAT(monthlyPaymentEx)
    const totalInc = months * monthlyPaymentInc
    const monthlyVAT = monthlyPaymentEx * (this.vat / 100.0)
    const currencyUnit = $(".installmentPayment").data('currency-unit')

    // set the values for the interactive calculator
    $(".installmentMarkup").html("" + markup)
    $(".installmentTotal").html(
      getI18nPrice({
        value: totalInc,
        unit: currencyUnit
      })
    )

    const LeasePrice = getI18nPrice({
      value: monthlyPaymentInc,
      unit: currencyUnit
    })

    $(".installmentPayment").html(
      LeasePrice
    )

    $(".installmentCount").html(months + '')
    $(".estimate .price .value").html(
      getI18nPrice({
        value: this.toLocalCurrency(monthlyPaymentInc),
        unit: ''
      })
    )

    // set the values for the calculation modal
    $('.monthly_price_ex').html(
      getI18nPrice({
        value: monthlyEx,
        unit: ''
      })
    )
    $('.monthly_price_ex.local_currency').html(
      getI18nPrice({
        value: this.toLocalCurrency(monthlyEx),
        unit: ''
      })
    )
    $('.monthly_markup_ex').html(
      getI18nPrice({
        value: monthlyMarkupEx,
        unit: ''
      })
    )
    $('.monthly_markup_ex.local_currency').html(
      getI18nPrice({
        value: this.toLocalCurrency(monthlyMarkupEx),
        unit: ''
      })
    )
    $('.monthly_vat').html(
      getI18nPrice({
        value: monthlyVAT,
        unit: ''
      })
    )
    $('.monthly_vat.local_currency').html(
      getI18nPrice({
        value: this.toLocalCurrency(monthlyVAT),
        unit: ''
      })
    )
    $('.monthly_total_price').html(
      getI18nPrice({
        value: monthlyPaymentInc,
        unit: ''
      })
    )
    $('.monthly_total_price.local_currency').html(
      getI18nPrice({
        value: this.toLocalCurrency(monthlyPaymentInc),
        unit: ''
      })
    )
  }
}
