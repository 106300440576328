
const AccessorMixin = {

    getDefaultProps() {
        return {
            label: stack => stack.label,
            values: stack => stack.values,
            x: e => e.x,
            y: e => e.y,
            y0: () => 0
        };
    }
}

export default AccessorMixin;
