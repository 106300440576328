import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { USING_MOBILE } from '../utils/browser';
import { Icon } from './icon';

// INTERFACE ///////////////////////////////////////////////////////////////////

export interface Props {
  /** Filled in search terms */
  value?: string
  /** 
   * If set, show a leading icon. The text is the classname of the icon
   * Use 'icon-search' to show a normal searching glass icon.
  */
  icon?: string
  /** If set, use this as the placeholder text in the input box. */
  placeholder?: string
  /** If set, show the given text as the submit button text */
  submitLabel?: string
  /** 
   * If true, this is a search action. Makes the input field a search field.
   * On iPhone that shows a blue search action button on the keyboard
   */
  search?: boolean
  /** If true, show a clear search X on the right */
  showClear?: boolean
  /** If true, disable text checks and completions on mobile keyboards */
  noCompletions?: boolean
  /** CSS Class(es) to add to the component as it is rendered */
  className?: string

  /** Called when the user presses the submit button */
  onSubmit?: () => void
  /** 
   * Called when the user alters the text value in the search, 
   * passing the current input value
   */
  onChange?: (value: string) => void
}

// COMPONENT ///////////////////////////////////////////////////////////////////

/**
 * Shows a text input with a search bar design, and allows for some features
 * that are handy for searching, such as adding a leading icon as well
 * as an optional placeholder text and clear button.
 */
export class SearchBar extends React.PureComponent<Readonly<Props>> {

  input: HTMLInputElement | null

  render() {
    return <form action="#" className={this.className} onSubmit={this.onSubmit}>

      <span className='input-group-addon'>
        {this.props.icon &&
          <i className={this.props.icon} />
        }
      </span>

      <input
        ref={el => this.input = el}
        type={this.props.search ? 'search' : 'text'}
        className='form-control search-bar-input'
        // placeholder={getI18nText({ id: 'placeholder.search' })}
        placeholder={this.props.placeholder}
        onChange={this.onSearchChange}
        value={this.props.value || ''}
        {...this.disableMobileInputFeatures}
      />

      {this.props.showClear && this.props.value &&
        <span className="input-group-addon">
          <i className="icon-cross search-reset" onClick={this.onClearSearch}></i>
        </span>
      }

      {this.props.submitLabel &&
        <span className='input-group-btn'>
          <button
            type='submit'
            className='btn btn-success'
          >
            <span className="submit-label d-none d-md-inline">{this.props.submitLabel}</span>
            <Icon name="search" className="d-md-none ml-0 ml-md-1"/>
          </button>
        </span>
      }
    </form>
  }

  get className() {
    let className = 'search-bar input-group' 
    if(this.props.className) {
      className += ' ' + this.props.className
    }
    return className
  }

  get disableMobileInputFeatures() {
    // only pass the mobile input props if we are on a mobile device
    return this.props.noCompletions && USING_MOBILE ? {
      autoComplete: "off",
      autoCorrect: "off",
      autoCapitalize: "off",
      spellCheck: false
    } : {}
  }

  // HANDLERS //////////////////////////////////////////////////////////////////

  @boundMethod onSubmit(event: React.FormEvent) {
    event.preventDefault()
    this.props.onSubmit && this.props.onSubmit()
    this.input && this.input.blur()
    return true
  }

  @boundMethod onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange && this.props.onChange(event.currentTarget.value)
  }

  @boundMethod onClearSearch() {
    this.props.onChange && this.props.onChange('')
  }

}
