/* rails-erb-loader-dependencies validators/zipcode_validator */

// The validator variable is a JSON Object
// The selector variable is a jQuery Object
ClientSideValidations.validators.local["zipcode"] = function(element, options) {
  var COUNTRIES_WITHOUT_POSTCODES = ["AO", "AG", "AW", "BS", "BZ", "BJ", "BW", "BF", "BI", "CM", "CF", "KM", "CG", "CD", "CK", "CI", "DJ", "DM", "GQ", "ER", "FJ", "TF", "GM", "GH", "GD", "GN", "GY", "HK", "IE", "JM", "KE", "KI", "MO", "MW", "ML", "MR", "MU", "MS", "NR", "AN", "NU", "KP", "PA", "QA", "RW", "KN", "LC", "ST", "SA", "SC", "SL", "SB", "SO", "ZA", "SR", "SY", "TZ", "TL", "TK", "TO", "TT", "TV", "UG", "AE", "VU", "YE", "ZW"]
  // TODO: HACK
  var selected_country_code = $("#checkout_country_code").val()
  if (COUNTRIES_WITHOUT_POSTCODES.indexOf(selected_country_code) === -1 && !element.val().trim()) {
    // When the value fails to pass validation you need to return the error message.
    return window.I18n.t("errors.messages.blank");
  }
}
