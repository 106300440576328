import React from "react"
import { AutoScalePlot } from "./auto_scale_plot"


interface Props {
  assets: Record<string, string>
  container: string
  id: number
  name: string
  stats: string // string format: [datestring, views][]
}

export class DomainTraffic extends React.Component<Props> {

  stats: [string, number][]

  constructor(props: Props) {
    super(props)
    this.stats = JSON.parse(this.props.stats)
  }

  render() {
    return <AutoScalePlot container={this.props.container} data={this.stats} />
  }
}
