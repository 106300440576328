/**
 * Disable or enable the element
 * @param JQuery object
 * @param isDisabled true if the element should be disabled, false if it should not be disabled
 */
export function setDisabled(element: JQuery<HTMLElement>, isDisabled?: boolean) {
  if(isDisabled !== false) {
    element.attr('disabled', 'disabled')
  } else {
    element.removeAttr('disabled')
  }
  return this
}

/**
 * Check if the element is within the viewport of the browser
 * @param element the element to check
 * @param expandViewport if passed, will enlarge the viewport, making the element easier to trigger. Can also be negative.
 * @returns true if the element is within the viewport, false if not, or undefined if it was not able to find the element
 */
export function isInViewport(element: JQuery<HTMLElement>, expandViewport?: number): true | false | undefined {
  const offset = element.offset()
  const outerHeight = element.outerHeight()
  const viewportTop = $(window).scrollTop()
  const windowHeight = $(window).height()
  
  if(offset === undefined || outerHeight === undefined || viewportTop === undefined || windowHeight === undefined) {
    return undefined
  }

  expandViewport = Math.max(outerHeight / -2, expandViewport || 0)

  const elementTop = offset.top
  const elementBottom = elementTop + outerHeight
  const viewportBottom = viewportTop + windowHeight

  return elementBottom > viewportTop - expandViewport && elementTop < viewportBottom + expandViewport
}
