import produce from 'immer';
import React from 'react';

/** Make a get API call using jquery ajax. */
export function callApiGet(url: string, params = {}): Promise<any> {
  // CHECK: what is the $ here? Anything that can be imported?
  return new Promise((window as any).$.get(url, params).then)
}

/** Testing function that renders the width size (XS, SM, MD, LG) as text */
export function renderBootstrapWidth() {
  return <div className="show-width">
    <div className="visible-xs">XS</div>
    <div className="visible-sm">SM</div>
    <div className="visible-md">MD</div>
    <div className="visible-lg">LG</div>
  </div>
}

/**
 * Update the state of a React component using ImmerJS.
 * 
 * Benefits of this wrapper over immer.produce:
 * - returns a promise of the state after performing the update
 * - does not expect a return value, letting you have more compact update calls
 * 
 * Example usages:
 * ```typescript
 * // easily update the state:
 * update(this, state => state.loading = true)
 * 
 * // passing a block of updates with update logic and deep property setting:
 * update(this, state => {
 *   state.loading = true
 *   if(newName) state.user.firstName = newName
 * })
 * 
 * // waiting for the state to be set before reading it:
 * async onButtonClick() {
 *   await update(this, state => state.active = !state.active)
 *   console.log('button active:', this.state.active)
 * }
 * 
 * ```
 * @param component the stateful React component to update the state for. Usually `this`.
 * @param updateFunction a function that receives the ImmerJS draft state, and manipulates it.
 * @returns a promise of the updated state, after the update has asynchronously been performed
 */
export async function update<State>(component: React.Component<any, State>, updateFunction: (draft: State) => void) {
  return new Promise<State>(resolve => {
    // @ts-ignore ts-migrate(2345) FIXME: Argument of type '<Base extends Immutable<State>>(... Remove this comment to see the full error message
    component.setState(produce(produceFn), resolve)
  })

  /** This wrapper function is used so if the updateFunction returns a value, this value is discared.
   * This allows usage of the update method like this:
   * ```update(this, state => state.loading = true)```
   */
  function produceFn(state: State) {
    updateFunction(state)
  }

}
