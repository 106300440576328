import { boundMethod } from "autobind-decorator"
import { extent, max } from "d3-array"
import { scaleTime } from "d3-scale"
import { curveBasis } from "d3-shape"
import { timeDay, timeMonth, timeWeek } from "d3-time"
import { timeFormat } from "d3-time-format"
import React from "react"
import { debounce } from "typescript-debounce-decorator"
import { formatQ, guessInterval, mkTick, nopInt, sumBy, toQ } from "../utils/stats"
import { AreaChart } from "./vendors/d3Components"

// const GRAPH_RATIO = 420 / 675
const GRAPH_RATIO = 240 / 675
const DEFAULT_WIDTH = 675

const FORMATTER = {
  day: timeFormat("%b %d"),
  week: timeFormat("%b %d"),
  month: timeFormat("%b '%y"),
  quarter: formatQ,
}

const TICKS = {
  day: mkTick(timeDay, 1),
  week: mkTick(timeWeek, 1),
  month: mkTick(timeMonth, 1),
  quarter: mkTick(timeMonth, 3),
}

interface Props {
  container: string
  data: [string, number][]
}

interface State {
  width: number
}

export class AutoScalePlot extends React.Component<Props, State> {
  interval: "first" | "day" | "week" | "month" | "quarter"
  margin: { top: number, bottom: number, left: number, right: number }
  domain: [string, string] | [undefined, undefined]
  xFormat: (date: Date) => string
  series: [{ values: [Date, number][] }]

  constructor(props: Props) {
    super(props)

    this.interval = guessInterval(this.props.data)
    const agg: [Date, number][] = summer(this.interval)(this.props.data)
    // CV: According to typing information, the max method returns a string, and log10 wants a number... so this
    // should not work. However, it does.. so forcing an ugly cast.
    const maxYAxisDigits = Math.ceil(Math.log10(max(agg, (d: any) => d.y) as any as number))
    const marginLeft = 18 + (8 * maxYAxisDigits)

    this.margin = { top: 20, bottom: 30, left: marginLeft, right: 40 }
    this.domain = extent(agg, (d: any) => d.x)
    this.xFormat = FORMATTER[this.interval]
    this.series = [{ values: agg }]

    this.state = {
      width: 0
    }
  }

  componentDidMount() {
    this.updateWidth()
    window.addEventListener("resize", this.updateWidth)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth)
  }

  render() {
    // recalculate these depending on the width of the graph
    const width = this.state.width || DEFAULT_WIDTH
    const heigth = GRAPH_RATIO * width
    const innerWidth = width - this.margin.left - this.margin.right
    const xScale: (value: number) => number = scaleTime().domain(this.domain).range([0, innerWidth])
    const ticks: Date[] = TICKS[this.interval](xScale)

    return <div className="product-traffic-chart">
      <AreaChart
        data={this.series}
        width={width}
        height={heigth}
        margin={this.margin}
        interpolate={curveBasis}
        xScale={xScale}
        xAxis={{ tickFormat: this.xFormat, tickValues: ticks }}
        yAxis={{ tickFormat: nopInt }}
        colorScale={() => "url(#plot-gradient)"}
        y0={() => 0}
      >
        <defs>
          <linearGradient id="plot-gradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="rgba(59, 247, 209, 1)" />
            <stop offset="100%" stopColor="rgba(56, 232, 176, 1)" />
          </linearGradient>
        </defs>
      </AreaChart>
    </div>
  }

  @boundMethod
  @debounce(250, { leading: false })
  updateWidth() {
    const container = document.getElementById(this.props.container)
    if (!container) {
      /* eslint-disable */
      console.error("Missing 'container' id prop")
      return
      /* eslint-enable */
    }

    this.setState({ width: container.offsetWidth + (2 * 15) })
  }
}

const SUMMER = {
  day: sumBy(timeDay),
  week: sumBy(timeWeek),
  month: sumBy(timeMonth),
  quarter: sumBy(toQ),
}

function summer(interval: "first" | "day" | "week" | "month" | "quarter") {
  return function (datas: [string, number][]) {
    return SUMMER[interval](datas).map(entry => ({
      x: new Date(entry.key),
      y: entry.value
    }))
  }
}
