import { isInViewport } from "../../shared/utils/jquery"
import { PurchasingOption } from "./offerbox/purchasing_option"
import { PurchasingOptionBuyNow } from "./offerbox/purchasing_option_buy_now"
import { PurchasingOptionLeaseToOwn } from "./offerbox/purchasing_option_lease_to_own"
import { PurchasingOptionMakeOffer } from "./offerbox/purchasing_option_make_offer"
import { PurchasingOptionRent } from "./offerbox/purchasing_option_rent"

const SHOW_FOOTER_EARLIER_PX = 200

// Attach the behavior to the offerbox layout
export function jqOfferBoxBehavior() {
  // attach behavior and validation to each possible option in the offerbox
  $('.offer-box #buyNow.purchasing-option').each(function () {
    $(this).data('purchase-option', new PurchasingOptionBuyNow(this))
  })

  $('.offer-box #rentDomain.purchasing-option').each(function () {
    $(this).data('purchase-option', new PurchasingOptionRent(this))
  })

  $('.offer-box #makeOffer.purchasing-option').each(function () {
    const option = new PurchasingOptionMakeOffer(this)
    $(this).data('purchase-option', option)
  })

  $(".offer-box #leaseToOwn.purchasing-option").each(function () {
    $(this).data("purchase-option", new PurchasingOptionLeaseToOwn(this))
  })

  // activate the next button, depending on if the last updated option is in a valid state
  $('.offer-box').each(function () {
    $(this).on('purchase-option', function (event, option: PurchasingOption) {
      setNextButton(option.valid, option.url)
    })

    $(this).on('submit', function (event, option: PurchasingOption) {
      setNextButton(option.valid, option.url)
      $('.offer-box .purchaseAction')[0].click()
    })
  })

  $(".offer-box [data-toggle='collapse']").first().trigger("click")

  // show a fixed offerbox footer on the screen based on the scroll position and a bit of slack
  $(window).on('scroll', function () {
    if( $('#offerbox-main').length == 0 ){ return }

    if (isInViewport($('#offerbox-main'), -SHOW_FOOTER_EARLIER_PX)) {
      $('#fixed-offerbox-footer').removeClass('d-block')
      $('.intercom-lightweight-app-launcher').show()
    } else {
      $('.parking-footer').css('padding-bottom', '9rem');
      $('#fixed-offerbox-footer').addClass('d-block')
      $('.intercom-lightweight-app-launcher').hide()
    }
  })
}

/** Set the next button behavior on the offerbox */
function setNextButton(enabled: boolean, url: string) {
  const button = $('.offer-box .purchaseAction')
  if (enabled) {
    button.removeAttr('disabled')
    button.attr('href', url)
  } else {
    button.attr('disabled', 'true')
    button.attr('href', 'javascript: void(0)')
  }
}
