import '@client-side-validations/client-side-validations';
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'
import ClientSideValidations from '@client-side-validations/client-side-validations';

import './validators/email'
import './validators/phone'
import './validators/vat'
import './validators/zipcode'
import './validators/minimum_bid'
import './validators/maximum_bid'
import './validators/custom_confirmation'


export function jqSetupClientSideValidations() {
  ClientSideValidations.callbacks.element.pass = function(element: JQuery, callback: Function) {
    // Allow clientSideValidations to do it's thing.
    callback();
    // Add a success message to give the user an ego lift.
    element.closest(".form-group").addClass('is-valid');
  };

  ClientSideValidations.callbacks.element.fail = function(element: JQuery, message: any, callback: Function) {
    callback();
    // Remove valid class
    element.closest(".form-group").removeClass('is-valid');
  }

  ClientSideValidations.start()
}
