import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

export function jqIntlTelInputBehavior() {
  $('.form-control.tel').each(function() {
    intlTelInput(this, {
      preferredCountries: ["NL", "US", "BE", "GB", "DE"],
      initialCountry: this.getAttribute('data-country') || undefined,
      hiddenInput: "phone"
    })
  })
}
