import '@client-side-validations/client-side-validations';
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'
import ClientSideValidations from '@client-side-validations/client-side-validations';
import { EU_COUNTRIES } from './eu_countries'
import { VAT_PLACEHOLDERS } from './vat_placeholders'

declare var I18n: any // lets typescript know that I18n was already declared

class Signup {
  form: JQuery;

  constructor(form: JQuery) {
    this.form = form;

    this.addValidationForHiddenElements();

    // hookup events
    $("[name='public_signup[is_company]']").change(this.onCompanyChange)
    $("[name='public_signup[country_code]']").change(this.onCountryChange)

    // call events for initial check
    $("[name='public_signup[is_company]']").change()
    $("[name='public_signup[country_code]']").change()
  }

  onCompanyChange() {
    let checked = $("[name='public_signup[is_company]']:checked").val() == 'true'

    $("#company_name").toggle(checked);
    $("#public_signup_company").prop("disabled", !checked);
    $("#public_signup_vat_number").prop("disabled", !checked);
  }

  onCountryChange() {
    let selected: string = <string>$("[name='public_signup[country_code]']").children("option:selected").val();
    let showVatNumberInput = EU_COUNTRIES.indexOf(selected) > -1;
    let showStateInput = selected == 'US';
    let labelForStateInput = VAT_PLACEHOLDERS[selected];

    $("#vat_number").toggle(showVatNumberInput)
    $("[name='public_signup[vat_number]']").prop("disabled", !showVatNumberInput)

    $("#state").toggle(showStateInput);
    $("[name='public_signup[state]']").prop("disabled", !showStateInput)
    $("[for=public_signup_vat_number]").html(labelForStateInput)
  }

  addValidationForHiddenElements() {
    let _form = this.form;

    // add hidden elements for validations
    ClientSideValidations.selectors.inputs += ', #public_signup_vat_number, #public_signup_company, #public_signup_state';

    let selectors: string[] = [
      "public_signup[vat_number]",
      "public_signup[company]",
      "public_signup[state]"
    ];

    let validators = {
      presence: [{ message: I18n.t('errors.messages.blank') }],
      allow_blank: [{ message: I18n.t('errors.messages.blank') }]
    };

    selectors.forEach(function (selector) {
      (_form.get(0) as any).ClientSideValidations.settings.validators[selector] = validators
    });

    (this.form as any).enableClientSideValidations();
  }
}

export function jqSignupBehavior() {
  $("#new_public_signup").each(function () {
    $(this).data("signup", new Signup($(this)));
  })
}