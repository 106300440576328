import { boundMethod } from 'autobind-decorator'
import $ from 'jquery'
import { PurchasingOption } from './purchasing_option'
import { getI18nPrice } from "../../../shared/utils/translate"
import { toHumanSize } from 'i18n-js'
import '@client-side-validations/client-side-validations';
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'
import ClientSideValidations from '@client-side-validations/client-side-validations';
declare var I18n: any // lets typescript know that I18n was already declared

export class PurchasingOptionMakeOffer extends PurchasingOption {

  constructor(element: HTMLElement) {
    super(element)

    // CV: respond to modal dialog being openened, we need to reattach
    // all event listeners then, because the dialog copies in the html
    const that = this
    $('a[data-content="offerCalculationModal"]').on('click', e => {
      setTimeout(() => {
        that.setEventHandlers()
        that.update()
      }, 100)
    })
    this.setEventHandlers()
    this.addValidationsForHiddenElements()
  }

  setEventHandlers() {
    const that = this;

    $('#public_domains_offer_bid').off('keyup')
    $('#public_domains_offer_bid').on('keyup', this.onNewValue)
    $('#public_domains_offer_bid').on('keypress', function(e) {
      if( e.which == 13 ){ // enter
        e.preventDefault();
        that.onSubmit()
      }
    })
  }

  @boundMethod onSubmit() {
    this.element.trigger('purchase-option', this)
    this.element.trigger('submit', this)
  }

  @boundMethod onNewValue() {
    this.element.trigger('purchase-option', this)
    this.update()
  }

  @boundMethod activate() {
    this.update()
    this.element.trigger('purchase-option', this)
  }

  update() {
    if(this.offer) {
      const offerEx = this.offer / ((100.0 + this.vat) / 100.0)
      const vat = offerEx * this.vat / 100.0

      $('#makeOffer .estimate .value').html(
        getI18nPrice({
          value: this.toLocalCurrency(this.offer),
          unit: ''
        })
      )
      $('.offer-calculation-modal .price_ex').html(
        getI18nPrice({
          value: offerEx,
          unit: ''
        })
      )
      $('.offer-calculation-modal .price_ex.local_currency').html(
        getI18nPrice({
          value: this.toLocalCurrency(offerEx),
          unit: ''
        })
      )
      $('.offer-calculation-modal .vat').html(
        getI18nPrice({
          value: vat,
          unit: ''
        })
      )
      $('.offer-calculation-modal .vat.local_currency').html(
        getI18nPrice({
          value: this.toLocalCurrency(vat),
          unit: ''
        })
      )
      $('.offer-calculation-modal .total_price').html(
        getI18nPrice({
          value: this.offer,
          unit: ''
        })
      )
      $('.offer-calculation-modal .total_price.local_currency').html(
        getI18nPrice({
          value: this.toLocalCurrency(this.offer),
          unit: ''
        })
      )
    } else {
      $('#makeOffer .estimate .value').html('')
    }
  }

  addValidationsForHiddenElements() {
    let _form = $('#new_public_domains_offer');

    // add hidden elements for validations
    ClientSideValidations.selectors.inputs += ', #public_domains_offer_bid';

    let selectors: string[] = [
      "public_domains_offer[bid]",
    ];

    (_form as any).enableClientSideValidations();
  }

  get url() {
    if (!this.offer) return this._url

    const url = new URL(this._url)
    url.searchParams.set('bid', this.offer.toString())
    return url.toString()
  }

  get valid(): boolean {
    const offer = this.offer
    return !!offer && this.validBid && this.wholeNumber
  }

  get validBid(): boolean {
    const offer = this.offer
    return !!offer && offer >= this.minimumBid && (!this.buyNowAvailable || (!this.buyNowPrice || offer <= this.buyNowPrice))
  }

  get wholeNumber(): boolean {
    const offer = this.offer
    return !!offer && offer % 1 === 0
  }

  get offer(): number | null {
    const value = $('#public_domains_offer_bid').val()
    return value ? parseFloat(value + '') : null
  }

  get buyNowPrice() {
    return $('#public_domains_offer_bid').data('buyNowPrice')
  }

  get buyNowAvailable() {
    return $('#public_domains_offer_bid').data('buyNowAvailable')
  }

  get minimumBid() {
    return $('#public_domains_offer_bid').data('minimumBid')
  }
}
