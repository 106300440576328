export function jqAccordionBehavior() {
  $('#accordionTools').on('hide.bs.collapse', function (t) {
    $(t.target)
      .parent()
      .removeClass('elevate')
  })

  $('#accordionTools').on('show.bs.collapse', function (t) {
    $('#accordionToolsImage')
      .removeClass()
      .addClass('background-image')
      .addClass($(t.target)
      .data('image'))
    $(t.target)
      .parent()
      .addClass('elevate')
  })

  $('#accordionTool').on('show.bs.collapse', function (t) {
    const video = $(t.target).data('video')
    $('.video').hide()
    $(`.video.${video}`).show()
  })
}
