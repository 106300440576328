export function jqCookiePopupBehavior() {
  const cookiePopup = document.getElementById('cookie-popup')
  if(!cookiePopup) { return }

  const permissionForm = cookiePopup.querySelector('form')
  if(!permissionForm) { return }

  permissionForm.addEventListener('submit', (e) => {
    e.preventDefault();

    $.post(permissionForm.action)
    .done(function() {
      cookiePopup.hidden = true
    })
  });
};
