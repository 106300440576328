/* rails-erb-loader-dependencies validators/phone_validator */

// The validator variable is a JSON Object
// The selector variable is a jQuery Object
window.ClientSideValidations.validators.local["phone"] = function(element, options) {
  var R = /^\+?[0-9 ().#*\/-]{5,25}$/i

  if (!R.test(element.val())) {
    // When the value fails to pass validation you need to return the error message.
    return window.I18n.t("errors.messages.phone");
  }
}
