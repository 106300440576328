import React from "react";

interface Props {
  name: string
  className?: string
}

export class Icon extends React.PureComponent<Props> {
  render() {
    return <svg className={this.className}>
      <use xlinkHref={this.packLocation + '/spritemap.svg#sprite-' + this.props.name} />
    </svg>
  }

  get className() {
    return "icon " + "icon-" + this.props.name + " " + (this.props.className || '')
  }

  get packLocation() {
    if( process.env.NODE_ENV == 'test' ){
      return '/packs-test'
    } else {
      return '/packs'
    }
  }
}
